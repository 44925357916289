<script setup>
import { ref, watch } from 'vue';
import FieldLabel from 'vue-elements/field/FieldLabel.vue';
import FieldError from 'vue-elements/field/FieldError.vue';
import {
    useErrorProps, useFieldHandlers,
    useInputProps,
    useLabelProps,
} from '../composables/input';

defineOptions({
    inheritAttrs: false,
});
const props = defineProps({
    ...useInputProps,
    ...useLabelProps,
    ...useErrorProps,
    showValid: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'text',
    },

});

const input = ref(null);
const emit = defineEmits(['update:modelValue', 'update:error', 'input', 'change']);

const {
    handleInput,
    handleChange,
} = useFieldHandlers(props, emit);

watch(() => props.error, (value) => {
    if (value) {
        input.value.setCustomValidity(value);
    } else {
        input.value.setCustomValidity('');
    }
});

</script>

<template>
  <div class="text-input">
    <FieldLabel
      v-if="label"
      :id="id"
      :label="label"
      :flag="required ? 'Verplicht' : null"
    />
    <div class="text-input-container">
      <input
        :id="id"
        ref="input"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        v-bind="$attrs"
        :class="{ 'show-valid': showValid }"
        @input="handleInput"
        @change="handleChange"
      >
    </div>

    <FieldError
      :error="error"
    />
  </div>
</template>
